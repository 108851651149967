body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html,
h3 {
  margin: 0;
}
a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiCustom-menu {
  width: 400px;
  max-height: 400px !important;
}

.MuiMenuItem-root {
  font-family: Montserrat-SemiBold !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.MuiTextField-root {
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"), url(./assets/fonts/montserrat/static/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"), url(./assets/fonts/montserrat/static/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"), url(./assets/fonts/montserrat/static/Montserrat-SemiBold.ttf) format("truetype");
}

.MuiInputBase-input-MuiFilledInput-input:-webkit-autofill {
  border-radius: unset !important;
}

.flag-dropdown {
  border: none !important;
  background-color: white !important;
  border-radius: 50px 0 0 50px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}

.radio-filled {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-style: solid;
  border-color: #104583;
  border-width: 2px;
  background-color: white;
}

input[type="file"]::file-selector-button {
  display: none;
}
