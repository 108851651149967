.carousel-container {
  height: 300px !important;
}

.carousel-item {
  height: 100%;
  width: 23%;
  display: flex;
  justify-content: center;
}

.image-wrapper {
  width: 85%;
  position: relative;
}

.carousel-item img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
  filter: brightness(65%);
}

.image-title {
  position: absolute;
  text-align: center;
  width: 100%;
  color: white;
  text-transform: capitalize;
  top: 10px;
  left: 0;
  right: 0;
  font-weight: 600;
  font-size: 30px;
  font-family: "Montserrat-Regular";
}

.react-multi-carousel-track {
  height: 100% !important;
}

.react-multiple-carousel__arrow--left {
  left: 7px !important;
}

.react-multiple-carousel__arrow--right {
  right: 7px !important;
}

.react-multiple-carousel__arrow {
  background: rgba(217, 217, 217, 0.54) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.react-multiple-carousel__arrow::before {
  color: #104583 !important;
}
